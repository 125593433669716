import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Swal from "sweetalert2"

import TableContainer from "../../components/Common/TableContainer"
import { toast, ToastContainer } from "react-toastify"
import {
  acceptOrder,
  getItemsDetails,
  getOrderDetails,
  rejectOrder,
  storeNotification,
} from "services/ApiService"
import Items from "./Items"
import { useNavigate } from "react-router-dom"

// import Swal from "sweetalert2"
// import "sweetalert2/dist/sweetalert2.min.css"

function Orders() {
  const [data, setData] = useState([])
  const [items, setItems] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [showItemsDetails, setShowItemsDetails] = useState(false)

  // State to toggle between tabs
  // const [isOpen, setIsOpen] = useEffect(true)

  const fetchData = () => {
    getOrderDetails()
      .then(res => {
        console.log("reponse", res)
        setData(res.data)
      })
      .catch(error => {
        console.error("Error fetching order details:", error)
        toast.error("Failed to fetch order details")
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleViewDetails = value => {
    getItemsDetails(value.orderId)
      .then(res => {
        setItems(res.data)
        setShowItemsDetails(true)
      })
      .catch(error => {
        console.error("Error fetching item details:", error)
        toast.error("Failed to fetch item details")
      })
  }

  var handleAcceptOrder = value => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then(result => {
      if (result.isConfirmed) {
        acceptOrder(value.orderId)
          .then(res => {
            storeNotification(value.orderId).then(res => {
              fetchData()
            })
            Swal.fire("Accepted!", "The order has been accepted.", "success")
          })
          .catch(err => {
            Swal.fire(
              "Error!",
              "There was an issue accepting the order.",
              "error"
            )
          })
      }
    })
  }

  // Reject Order
  var handleRejectOrder = value => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to reject this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
    }).then(result => {
      if (result.isConfirmed) {
        rejectOrder(value.orderId)
          .then(res => {
            storeNotification(value.orderId).then(res => {
              fetchData()
            })
            Swal.fire("Rejected!", "The order has been rejected.", "success")
          })
          .catch(err => {
            Swal.fire(
              "Error!",
              "There was an issue rejecting the order.",
              "error"
            )
          })
      }
    })
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Order Id",
        accessor: "orderId",
      },
      {
        Header: "Order Name",
        accessor: "orderName",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Mobile",
        accessor: "contact",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "View Details",

        Cell: ({ row }) => (
          <button
            className="badge bg-primary d-inline-block font-size-11 text-center border-0"
            style={{
              padding: "8px 16px",
              minWidth: "100px",
              borderRadius: "15px",
            }}
            onClick={() => handleViewDetails(row.original)}
          >
            Show Items
          </button>
        ),
      },
      {
        Header: "Action",

        Cell: ({ row }) =>
          row.original.isAccept ? (
            <div>
              <span
                className="font-size-11 badge-soft-success badge bg-secondary m-1 px-2 py-1"
                style={{ borderRadius: "5px" }}
              >
                Accepted
              </span>

              <button
                className="badge bg-danger d-inline-block font-size-11 text-center  border-0"
                style={{
                  padding: "8px 16px",
                  minWidth: "100px",
                  borderRadius: "15px",
                }}
                onClick={() => handleRejectOrder(row.original)}
              >
                Cancel Order
              </button>
            </div>
          ) : (
            <button
              className="badge bg-success d-inline-block font-size-11 text-center  border-0"
              style={{
                padding: "8px 16px",
                minWidth: "100px",
                borderRadius: "15px",
              }}
              onClick={() => handleAcceptOrder(row.original)}
            >
              Accept Order
            </button>
          ),
      },
    ]

    // Conditionally add the GST Number column if the active tab is "withGst"

    return baseColumns
  })

  // Function to switch tabs

  //meta title
  document.title = "Users | Stelo - Steel Trading Dashboard     "

  return (
    <>
      <div className="page-content">
        <ToastContainer />
        <div className="container-fluid">
          {!showItemsDetails ? (
            <TableContainer
              columns={columns}
              data={data}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
              isPagination={true}
              tableClass="align-middle table-nowrap table-check table"
              theadClass="table-light"
              paginationDiv="col-12"
              pagination="justify-content-center pagination pagination-rounded"
            />
          ) : (
            <Items items={items} />
          )}
        </div>
      </div>

      <div></div>
    </>
  )
}

Orders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Orders

import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { addMaterials, deleteMaterial, getMaterials } from "services/ApiService"
import { FaTrash } from "react-icons/fa" // Importing delete icon from react-icons
import { Modal, Button } from "react-bootstrap"
import "./Material.css" // Import custom CSS
import { toast, ToastContainer } from "react-toastify"

function Material() {
  const [materials, setMaterials] = useState([])
  const [showModal, setShowModal] = useState(false) // State to manage modal visibility
  const [newMaterial, setNewMaterial] = useState("")

  const fetchMaterial = () => {
    getMaterials()
      .then(res => {
        setMaterials(res.data)
      })
      .catch(error => {
        console.error("Error fetching materials:", error)
      })
  }

  useEffect(() => {
    fetchMaterial()
  }, [])

  const handleDelete = materialId => {
    // SweetAlert confirmation popup
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(result => {
      if (result.isConfirmed) {
        // If the user confirms deletion, call the delete API
        deleteMaterial(materialId)
          .then(res => {
            fetchMaterial() // Refresh the material list
            toast.success(res.msg) // Show success toast
            Swal.fire("Deleted!", "The material has been deleted.", "success") // Show success alert
          })
          .catch(error => {
            console.error("Error deleting material:", error)
            Swal.fire(
              "Error!",
              "There was an issue deleting the material.",
              "error"
            ) // Show error alert
          })
      }
    })
  }
  const handleAddMaterial = () => {
    var reqObj = {
      material: newMaterial,
    }
    addMaterials(reqObj)
      .then(res => {
        toast.success(res.msg)
        fetchMaterial()
        setNewMaterial("")
      })
      .catch(error => {
        console.error("Error fetching materials:", error)
      })
    // Logic to add the new material (API call or local update)
    // console.log("Adding material:", newMaterial)
    setShowModal(false) // Close the modal after adding
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="material-page mt-5 mb-5 pb-5 pt-5">
        <div className="d-flex flex-row-reverse ">
          <button
            className="badge bg-primary d-inline-block font-size-13 text-center border-0"
            style={{
              padding: "12px 19px",
              minWidth: "100px",
              borderRadius: "17px",
              marginRight: "42px",
            }}
            onClick={() => setShowModal(true)}
          >
            + Add Materials
          </button>
        </div>
        <div className="material-container">
          {materials.map((item, indx) => (
            <div className="material-item" key={indx}>
              <span className="material-name">{item.material}</span>
              <button
                className="delete-button"
                onClick={() => handleDelete(item.id)}
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>

        {/* Add Material Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Material</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Enter material"
              value={newMaterial}
              onChange={e => setNewMaterial(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddMaterial}>
              Add Material
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Material

import { router } from "utils/APIUrls"
import axiosConnectorInstance from "./AxiosConnector"

export const getUser = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(
      router?.get_user,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const addCategory = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.add_category,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const editCategory = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.edit_category,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getCategories = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(
      router?.get_category,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}
export const getCategoriesById = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.get_categoryById}?catId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const deleteCategory = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.delete_category}?catId=${id}`
    )
    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}


export const blockUser = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.block_user}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const unblockUser = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.unblock_user}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const approveGstStatus = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.approve_gst_status}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const disapproveGstStatus = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.disapprove_gst_status}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

// get order details

export const getOrderDetails = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(router?.get_order_details)

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getItemsDetails = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.get_items_details}?orderId=${id}`
    )
    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getMaterials = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(router?.get_materials)

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const addMaterials = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.add_material,
      requestPayload
    )
    // console.log("Add material response", response)
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const deleteMaterial = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.delete_materials}?materialId=${id}`
    )
    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const acceptOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.accept_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const rejectOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.reject_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const storeNotification = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.store_notification}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

import React, { useEffect, useMemo, useState } from "react"
import Swal from "sweetalert2"
import PropTypes from "prop-types"
import { deleteCategory, getCategories } from "services/ApiService"
import TableContainer from "../../components/Common/TableContainer"
import { Button } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import UpdateCategory from "pages/AddCategory/UpdateCategory"

function DatatableTables() {
  const [data, setData] = useState([])
  const [modal, setModal] = useState(false) // State to handle modal visibility
  const [selectedCategoryId, setSelectedCategoryId] = useState("")
  var navigate = useNavigate()

  const fetchData = () => {
    getCategories().then(res => {
      setData(res.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])
  console.log("categroy data", data)
  var handleCategory = () => {
    navigate("/add-category")
  }
  // var hcandleDeleteCategory = id => {
  //   if (window.confirm("Are you sure to delete this category ?")) {
  //     deleteCategory(id).then(res => {
  //       toast.success(res.msg)
  //       fetchData()
  //     })
  //   }
  // }

  const handleDeleteCategory = id => {
    // SweetAlert confirmation popup
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(result => {
      if (result.isConfirmed) {
        // If the user confirms deletion, call the delete API
        deleteCategory(id)
          .then(res => {
            fetchData() // Refresh the material list
            toast.success(res.msg) // Show success toast
            Swal.fire("Deleted!", "The category has been deleted.", "success") // Show success alert
          })
          .catch(error => {
            console.error("Error deleting category:", error)
            Swal.fire(
              "Error!",
              "There was an issue deleting the category.",
              "error"
            ) // Show error alert
          })
      }
    })
  }

  const handleUpdateCategory = id => {
    setSelectedCategoryId(id)
    setModal(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "category",
        headerStyle: { textAlign: "center" },
      },
      {
        Header: "Outer Diameter",
        accessor: "outerDiameter",
        headerStyle: { textAlign: "center" },
        Cell: ({ value }) => (
          <span
            className={`badge ${value ? "bg-success" : "bg-danger"}`}
            style={{
              paddingTop: "5px",
              borderRadius: "50%",
              color: "white",
              marginLeft: "38px",
              width: "18px",
              height: "18px",
            }}
          >
            {value ? "✓" : "✗"}
          </span>
        ), // Green background for check, red for cross
      },
      {
        Header: "Inner Diameter",
        accessor: "innerDiameter",
        headerStyle: { textAlign: "center" },
        Cell: ({ value }) => (
          <span
            className={`badge ${value ? "bg-success" : "bg-danger"}`}
            style={{
              paddingTop: "5px",
              borderRadius: "50%",
              color: "white",
              marginLeft: "38px",
              width: "18px",
              height: "18px",
            }}
          >
            {value ? "✓" : "✗"}
          </span>
        ), // Green background for check, red for cross
      },
      {
        Header: "Length",
        accessor: "length",
        headerStyle: { textAlign: "center" },
        Cell: ({ value }) => (
          <span
            className={`badge ${value ? "bg-success" : "bg-danger"}`}
            style={{
              paddingTop: "5px",
              borderRadius: "50%",
              color: "white",
              marginLeft: "15px",
              width: "18px",
              height: "18px",
            }}
          >
            {value ? "✓" : "✗"}
          </span>
        ),
      },
      {
        Header: "Height And Width",
        accessor: "heightAndWidth",
        headerStyle: { textAlign: "center" },
        Cell: ({ value }) => (
          <span
            className={`badge ${value ? "bg-success" : "bg-danger"}`}
            style={{
              paddingTop: "5px",
              borderRadius: "50%",
              color: "white",
              marginLeft: "42px",
              width: "18px",
              height: "18px",
            }}
          >
            {value ? "✓" : "✗"}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        headerStyle: { marginLeft: "10px" },
        Cell: ({ value }) => (
          <div style={{}}>
            <button
              className="badge bg-danger  font-size-11 text-center border-0 m-1"
              style={{
                padding: "8px 10px",
                minWidth: "100px",
                borderRadius: "15px",
              }}
              onClick={() => handleDeleteCategory(value)}
            >
              Delete
            </button>

            <button
              className="badge bg-info  font-size-11 text-center border-0"
              style={{
                padding: "8px 10px",
                minWidth: "100px",
                borderRadius: "15px",
              }}
              onClick={() => handleUpdateCategory(value)}
            >
              Update
            </button>
          </div>
        ),
      },
    ],
    []
  )

  //meta title
  document.title = "Users | Stelo - Steel Trading Dashboard "

  return (
    <div className="page-content">
      <ToastContainer />

      <div className="container-fluid">
        {/* Add Button to open the Add Category modal */}
        <div className="d-flex flex-row-reverse">
          <Button
            className="badge bg-primary d-inline-block font-size-13 text-center border-0"
            style={{
              padding: "12px 19px",
              minWidth: "100px",
              borderRadius: "17px",
            }}
            onClick={handleCategory}
          >
            + Add Category
          </Button>
        </div>

        {/* Conditionally render the table based on the active tab */}
      </div>

      {!modal ? (
        <TableContainer
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          isPagination={true}
          tableClass="align-middle table-nowrap table-check table"
          theadClass="table-light"
          paginationDiv="col-12"
          pagination="justify-content-center pagination pagination-rounded"
        />
      ) : (
        <UpdateCategory
          modal={modal}
          setModal={setModal}
          categoryId={selectedCategoryId}
        />
      )}
    </div>
  )
}

DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables

import React, { useState } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap"

const ChatDesign = () => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [message, setMessage] = useState("")

  const users = ["User 1", "User 2", "User 3", "User 4", "User 5"]

  const handleUserClick = user => {
    setSelectedUser(user)
  }

  const handleSendMessage = () => {
    // Functionality to send message will be implemented here
    console.log("Message sent:", message)
    setMessage("") // Clear the input field after sending
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg="4" md="5">
            <Card style={{ height: "80vh" }}>
              <div className="p-3">
                <h5 className="font-size-15 mb-3">Users</h5>
                <Input
                  type="text"
                  placeholder="Search users..."
                  className="mb-3"
                />
                <div style={{ overflowY: "auto", maxHeight: "65vh" }}>
                  <div className="d-flex border-bottom py-2">
                    <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center mx-3">
                      {"Priyank".charAt(0)}
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mt-0 mb-1">
                        Priyank Gundaraniya
                      </h5>
                      <p className="text-muted mb-0 font-size-12">
                        <i className="mdi mdi-circle text-success align-middle me-2 font-size-10" />
                        Online
                      </p>
                    </div>
                  </div>

                  <div className="d-flex border-bottom py-2">
                    <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center mx-3">
                      {"Keyur".charAt(0)}
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mt-0 mb-1">Keyut Patel</h5>
                      <p className="text-muted mb-0 font-size-12">
                        <i className="mdi mdi-circle text-success align-middle me-2 font-size-10" />
                        Online
                      </p>
                    </div>
                  </div>

                  <div className="d-flex border-bottom py-2">
                    <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center mx-3">
                      {"Rohan".charAt(0)}
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mt-0 mb-1">Rohan Vadaliya</h5>
                      <p className="text-muted mb-0 font-size-12">
                        <i className="mdi mdi-circle text-success align-middle me-2 font-size-10" />
                        Online
                      </p>
                    </div>
                  </div>

                  <div className="d-flex border-bottom py-2">
                    <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center mx-3">
                      {"Vinit".charAt(0)}
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mt-0 mb-1">Vinit</h5>
                      <p className="text-muted mb-0 font-size-12">
                        <i className="mdi mdi-circle text-success align-middle me-2 font-size-10" />
                        Online
                      </p>
                    </div>
                  </div>

                  <div className="d-flex border-bottom py-2">
                    <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center mx-3">
                      {"Smit".charAt(0)}
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mt-0 mb-1">Smi..</h5>
                      <p className="text-muted mb-0 font-size-12">
                        <i className="mdi mdi-circle text-success align-middle me-2 font-size-10" />
                        Online
                      </p>
                    </div>
                  </div>

       


                </div>
              </div>
            </Card>
          </Col>

          <Col lg="8" md="7">
            <Card style={{ height: "80vh" }}>
              <div className="p-3 border-bottom">
                <h5 className="font-size-15 mb-1">Select a user to chat</h5>
              </div>

              <div
                className="chat-box p-3"
                style={{ flexGrow: 1, overflowY: "auto", height: "60vh" }}
              >
                {/* Chat messages will go here */}
                <div className="d-flex align-items-start mb-3">
                  <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center">
                    {selectedUser ? selectedUser.charAt(0) : ""}
                  </div>
                  <div className="ms-3">
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                  </div>
                </div>

                <div className="d-flex align-items-start mb-3">
                  <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center">
                    {selectedUser ? selectedUser.charAt(0) : ""}
                  </div>
                  <div className="ms-3">
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                  </div>
                </div>

                <div className="d-flex align-items-start mb-3">
                  <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center">
                    {selectedUser ? selectedUser.charAt(0) : ""}
                  </div>
                  <div className="ms-3">
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                  </div>
                </div>

                <div className="d-flex align-items-start mb-3">
                  <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center">
                    {selectedUser ? selectedUser.charAt(0) : ""}
                  </div>
                  <div className="ms-3">
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                  </div>
                </div>

                <div className="d-flex align-items-start mb-3">
                  <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center">
                    {selectedUser ? selectedUser.charAt(0) : ""}
                  </div>
                  <div className="ms-3">
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                    <p className="bg-light p-2 rounded">Hi, how are you?</p>
                  </div>
                </div>
                {/* Additional chat messages can be added here */}
              </div>

              <div className="p-3 border-top">
                <Form>
                  <FormGroup className="d-flex mb-0">
                    <Input
                      type="text"
                      placeholder="Enter your message..."
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      style={{
                        borderRadius: "20px",
                        padding: "10px 15px",
                        border: "1px solid #ccc",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        fontSize: "14px",
                        flexGrow: 1,
                      }}
                    />
                    <Button
                      color="primary"
                      onClick={handleSendMessage}
                      style={{
                        borderRadius: "50%",
                        width: "45px",
                        height: "45px",
                        marginLeft: "10px",
                        backgroundColor: "#007bff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 4px 6px rgba(0, 123, 255, 0.3)",
                      }}
                    >
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ChatDesign

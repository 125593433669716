import React from "react"
import "./Items.css" // Import custom CSS
import { useNavigate } from "react-router-dom"

function Items(props) {
  const navigate = useNavigate()
  console.log("====================================")
  console.log(props.items)
  console.log("====================================")
  return (
    <div className="items-container">
      <button
        className="badge bg-primary d-inline-block font-size-11 text-center border-0 mb-2"
        style={{
          padding: "8px 16px",
          minWidth: "100px",
          borderRadius: "15px",
        }}
        onClick={() => window.location.reload()}
      >
        Go Back..
      </button>
      <div className="order-info row justify-content-center mb-4">
        {/* Order Id */}
        <div className="col-12 col-md-6 text-center mb-2">
          <h5 className="order-id text-secondary">Order Id:</h5>
          <span className="order-value">{props.items[0].orderId}</span>
        </div>
        {/* Order Name */}
        <div className="col-12 col-md-6 text-center mb-2">
          <h5 className="order-name text-secondary">Order Name:</h5>
          <span className="order-value">{props.items[0].orderName }</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-striped align-middle">
          <thead className="table-header">
            <tr>
              <th>Category</th>
              <th>Material</th>
              <th>Quantity</th>
              <th>Height</th>
              <th>Inner Diameter</th>
              <th>Outer Diameter</th>
              <th>Length</th>
              <th>Width</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item, indx) => (
              <tr key={indx}>
                <td>{item.category}</td>
                <td>{item.material}</td>
                <td>{item.quantity}</td>
                <td>{item.height ? `${item.height} mm` : "-"}</td>
                <td>{item.innerDiameter ? `${item.innerDiameter} mm` : "-"}</td>
                <td>{item.outerDiameter ? `${item.outerDiameter} mm` : "-"}</td>
                <td>{item.length ? `${item.length} mm` : "-"}</td>
                <td>{item.width ? `${item.width} mm` : "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Items

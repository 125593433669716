import axios from "axios";
import { toast } from "react-toastify";
import { base_url } from "../utils/APIUrls";

// const token = localStorage.getItem("authorizedToken");

const axiosConnectorInstance = axios.create({
  baseURL: base_url,
  timeout: 60000,
});

axiosConnectorInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("authorizedToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

axiosConnectorInstance.interceptors.response.use(
  function (response) {
    console.log("response: ", response);
    return response;
  },
  function (error) {
    console.log("api error: ", error);
    if (error !== null && error?.response?.status === 401) {
      window.localStorage.clear();
      window.location.reload();
    }
    const message =
      error.code === "ECONNABORTED"
        ? "Check your internet connection"
        : error.message || "Something went wrong!";

    toast.error(message);
    return Promise.reject(error || "Something went wrong!");
  }
);

export default axiosConnectorInstance;

// export const base_url = "http://143.110.253.43:9090/api/user";
export const base_url = "http://143.110.253.43:9090/api/admin"
export const socket_url = `http://localhost:9090`

export var router = {
  otp_send: "/otp-send-admin",
  otp_verify: "/otp-verify-admin",
  get_user: "/get-user",
  add_category: "/add-category",
  edit_category: "/update-category",
  get_category: "/get-categories",
  get_categoryById: "/get-categoriesById",
  delete_category: "/delete-categories",
  block_user: "/block-user",
  unblock_user: "/unblock-user",
  approve_gst_status: "/approve-gst-status",
  disapprove_gst_status: "/disapprove-gst-status",
  get_order_details: "/get-order-details-admin",
  get_items_details: "/get-items-details-admin",
  add_material: "/add-material",
  get_materials: "/get-materials",
  delete_materials: "/delete-materials",
  reject_order: "/reject-order",
  accept_order: "/accept-order",
  store_notification: "/store-notification-admin",
}

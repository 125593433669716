import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  approveGstStatus,
  blockUser,
  deleteCategory,
  disapproveGstStatus,
  getUser,
  unblockUser,
  updateGstStatus,
} from "services/ApiService"
import TableContainer from "../../components/Common/TableContainer"
import { toast, ToastContainer } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"
// import Swal from "sweetalert2"
// import "sweetalert2/dist/sweetalert2.min.css"

function DatatableTables() {
  const [data, setData] = useState([])
  const [activeTab, setActiveTab] = useState("withGst") // State to toggle between tabs

  const fetchData = () => {
    getUser().then(res => {
      setData(res.data)
    })
  }

  const handleApprove = userId => {
    if (window.confirm("Are you sure to approve this GST ?")) {
      approveGstStatus(userId).then(res => {
        toast.success(res.msg)
        fetchData()
      })
    }
  }

  const handleDisapprove = userId => {
    if (window.confirm("Are you sure  to disapprove this GST ?")) {
      disapproveGstStatus(userId).then(res => {
        toast.success(res.msg)
        fetchData()
      })
    }
  }

  const handleBlock = id => {
    if (window.confirm("Are you sure  to Block this user ?")) {
      blockUser(id).then(res => {
        toast.success(res.msg)
        fetchData()
      })
    }
  }

  const handleUnblock = id => {
    if (window.confirm("Are you sure  to Block this user ?")) {
      unblockUser(id).then(res => {
        toast.success(res.msg)
        fetchData()
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  // Filter the users based on GST number
  const usersWithGst = data.filter(user => user.gst)
  const usersWithoutGst = data.filter(user => !user.gst)

  // // Determine if we should show the GST column
  // const shouldShowGstColumn = activeTab === "withGst" && usersWithGst.length > 0

  // Dynamically determine the columns to render
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "GST Approve",
        accessor: "id1",
        Cell: ({ value, row }) =>
          row.original.gst ? (
            row.original.isGstVerified ? (
              <>
                {/* <span
                  className="font-size-11 badge-soft-success badge bg-secondary px-2 py-1"
                  style={{ borderRadius: "5px" }}
                >
                  Approved
                </span> */}
                <button
                  className="badge d-inline-block font-size-11 text-center border-0"
                  style={{
                    backgroundColor: "#ff4d4d", // Red for block
                    color: "white",
                    padding: "8px 16px",
                    minWidth: "100px",
                   
                    borderRadius: "15px",
                  }}
                  onClick={() => handleDisapprove(value)}
                >
                  Disapprove
                </button>
              </>
            ) : (
              <button
                className="badge bg-info d-inline-block font-size-11 text-center border-0"
                style={{
                  backgroundColor: "#ff4d4d", // Red for block
                  color: "white",
                  padding: "8px 16px",
                  minWidth: "100px",
                  borderRadius: "15px",
                }}
                onClick={() => handleApprove(value)}
              >
                Approve
              </button>
            )
          ) : (
            ""
          ),
      },
      {
        Header: "Active / Inactive",
        accessor: "id2",
        Cell: ({ value, row }) =>
          row.original.isActive ? (
            <button
              className="badge d-inline-block font-size-11 text-center border-0"
              style={{
                backgroundColor: "#ff4d4d", // Red for block
                color: "white", // White text
                padding: "8px 16px",
                minWidth: "100px",
                marginLeft: "10px",
                borderRadius: "15px",
              }}
              onClick={() => handleBlock(value)}
            >
              <FontAwesomeIcon icon={faLock} /> Block
            </button>
          ) : (
            <>
              <button
                className="badge d-inline-block font-size-11 text-center border-0"
                style={{
                  backgroundColor: "#4CAF50", // Green for unblock
                  color: "white", // White text
                  padding: "8px 16px",
                  minWidth: "100px",
                  borderRadius: "15px",
                }}
                onClick={() => handleUnblock(value)}
              >
                <FontAwesomeIcon icon={faUnlock} /> Unblock
              </button>
            </>
          ),
      },
    ]

    // Conditionally add the GST Number column if the active tab is "withGst"
    if (activeTab === "withGst") {
      baseColumns.splice(4, 0, {
        Header: "GST Number",
        accessor: "gst",
      })
    }

    return baseColumns
  }, [activeTab])

  // Function to switch tabs
  const handleTabSwitch = tabName => {
    setActiveTab(tabName)
  }

  //meta title
  document.title = "Users | Stelo - Steel Trading Dashboard "

  return (
    <div className="page-content">
      <ToastContainer />
      <div className="container-fluid">
        {/* Add Tabs */}
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={` nav-link ${activeTab === "withGst" ? "active" : ""}`}
              onClick={() => handleTabSwitch("withGst")}
            >
              GST Added Users
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${
                activeTab === "withoutGst" ? "active" : ""
              }`}
              onClick={() => handleTabSwitch("withoutGst")}
            >
              Users Without GST
            </button>
          </li>
        </ul>

        {/* Conditionally render the table based on the active tab */}
        <TableContainer
          columns={columns}
          data={activeTab === "withGst" ? usersWithGst : usersWithoutGst}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          isPagination={true}
          tableClass="align-middle table-nowrap table-check table"
          theadClass="table-light"
          paginationDiv="col-12"
          pagination="justify-content-center pagination pagination-rounded"
        />
      </div>
    </div>
  )
}

DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
